<div class="modal-substrate" (click)="onModalClose($event)">
    <div class="modal-block" (click)="$event.stopPropagation()">
      <button class="close-btn" fill="none" (click)="onModalClose($event)"><mat-icon>close</mat-icon></button>
      <span class="title">{{textSets[cancelationType].title}}</span>
      <span class="text" [innerHTML]="textSets[cancelationType].text"></span>
      <span class="more-text" [innerHTML]="textSets[cancelationType].paragraph"></span>
      <form action="" [formGroup]="form" style="align-self: flex-start; margin-left: 65px; width: 100%;">
        <div class="radio-group-block">
          <div class="radio-block" *ngFor="let radio of radioArr" >
            <div style="display: flex; flex-direction: column; width: 400px;">
              <div style="display:flex; align-items: center;" (click)="onRadioClick(radio.value, !!(radio.placeholder && radio.title))">
                <input type="radio" value="{{radio.value}}" formControlName="reason" >
                <span class="radio-label">
                    {{radio.label}}
                </span>
              </div>
                <div *ngIf="form.get('reason')?.value === 'Other' && radio.value == 'Other'" class="input-block">
                  <span class="label">Please provide a reason for this cancelation</span>
                  <input formControlName="reasonNotes" type="text"  class="other-text-input" placeholder="Enter a reason for this cancelation" [ngClass]="{'error-input': !form.valid && form.get('reasonNotes').dirty}">
                  <span class="error" *ngIf="!form.valid && form.get('reasonNotes').dirty">
                    Please enter a text between 10 and 280 characters in length.
                  </span>
                  </div>
              <div *ngIf="form.get('reason')?.value == 'Filled by other agency' && radio.value == 'Filled by other agency'" class="input-block">
                  <span class="label">Agency name <span class="optional"> (optional)</span></span>
                  <input formControlName="reasonNotes" type="text"  class="other-text-input" placeholder="Enter agency name" [ngClass]="{'error-input': !form.valid && form.get('reasonNotes').dirty}">
                  <span class="error" *ngIf="!form.valid && form.get('reasonNotes').dirty">
                    Please enter a text between 2 and 280 characters in length.
                  </span>
              </div>
              <div *ngIf="form.get('reason')?.value == 'Nurse can not work at our facility' && radio.value == 'Nurse can not work at our facility'" class="input-block">
                  <span class="label">Please provide additional details</span>
                  <input formControlName="reasonNotes" type="text"  class="other-text-input" placeholder="Enter reason why nurse can't work at the facility" [ngClass]="{'error-input': !form.valid && form.get('reasonNotes').dirty}">
                  <span class="error" *ngIf="!form.valid && form.get('reasonNotes').dirty">
                    Please enter a text between 10 and 280 characters in length.
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="lastMinute" class="divider"></div>
        <div class="dontShowAgain" *ngIf="lastMinute">
          <input class="last-minute-cancel" type="checkbox" [formControlName]="'checkbox'">
          <span>{{textSets[cancelationType].checkboxLabel}}</span>
        </div>
      </form>
      <div class="buttons-block" [ngClass]="{'buttons-last-minute':lastMinute}">
        <button (click)="onModalClose($event)" fill="outline" [ngClass]="{'confirm-button-last-minute, cancel-button-last-minute':lastMinute}" color="border-base">Cancel</button>
        <button [ngClass]="{'disabled-submit':(lastMinute && form.get('checkbox').value != true || form.get('reason').invalid || form.get('reasonNotes').invalid)}"
                [disabled]="(lastMinute && form.get('checkbox').value != true || form.get('reason').invalid|| form.get('reasonNotes').invalid)" 
                [color]="'danger'" 
                (click)="onOkClick($event);">
                {{textSets[cancelationType].submitText}}
        </button>
      </div>
    </div>
</div>
