import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { SHIFTS_PAGE_SIZE } from 'src/app/constants/pagination';
import { parametrify } from 'src/app/helpers/parametrify';
import { ApplicantModel } from 'src/app/models/applicant';
import { CalculatorModel } from 'src/app/models/calculator';
import { NurseModel } from 'src/app/models/nurse';
import { ShiftModel } from 'src/app/models/shift';
import { CreateShiftsDTO, PaginationDTO, SortDTO } from 'src/app/types/dto';
import { EditShiftDTO } from 'src/app/types/dto/edit-shift';
import { ShiftsFiltersDTO } from 'src/app/types/dto/fetch-shifts/shifts-filters';
import { environment } from 'src/environments/environment';
import { NurseCancelationForm } from '../../shared/components/cancel-nurse-modal/nurse-cancelation-form';

@Injectable()
export class ShiftsService {
  private endpoint = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  fetchShifts(
    facilityId: string,
    filters: ShiftsFiltersDTO,
    pagination: PaginationDTO = { skip: 0, limit: SHIFTS_PAGE_SIZE },
    sort: SortDTO = { order: 'desc', sort: '_id' },
    withDnrs = false
  ) {
    const getDeleted = !!filters.q;
    let updFilters = { ...filters, shiftDateFrom: filters.startTimeFrom, shiftDateTo: filters.startTimeTo };
    delete updFilters.startTimeFrom;
    delete updFilters.startTimeTo;
    let params = new HttpParams({
      fromObject: {
        facilities: [facilityId],
        ...parametrify(updFilters),
        ...parametrify(pagination),
        ...parametrify(sort),
        ...(withDnrs ? {withDnrs: 'true'} : {}),
        populate: [
          'applicants.user',
          'shiftData.shiftTemplate',
          'facility.shiftTemplates',
          'holiday',
          'applicants.confirmedBy'
        ],
      },
    });
    getDeleted ? params = params.set('includeDeleted', true) : null;
    return this.http
      .get<any>(`${this.endpoint}/shifts`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return {
            models: withDnrs ? response.body.shifts : response.body,
            dnrs: withDnrs ? response.body.dnrs : [],
            totalCount:
              parseInt(response.headers.get('X-Total-Count')) ||
              Number.MAX_SAFE_INTEGER,
          };
        }),
      );
  }
  fetchShift(shiftId: string) {
    const params = new HttpParams({
      fromObject: {
        populate: [
          'applicants.user',
          'facility.shiftTemplates',
        ],
      },
    });
    return this.http.get<ShiftModel>(`${this.endpoint}/shifts/${shiftId}`, {params});
  }
  confirmApplicant(shiftId: string, applicantId: string, increaseSlots: boolean) {
    return this.http.post<ShiftModel>(
      `${this.endpoint}/shifts/${shiftId}/accept-applicant/${applicantId}`,
      {'increaseSlots': increaseSlots},
    );
  }

  declineApplicant(shiftId: string, applicantId: string) {
    return this.http.post<ShiftModel>(
      `${this.endpoint}/shifts/${shiftId}/decline/${applicantId}`,
      {},
    );
  }

  cancelApplicant(shiftId: string, applicantId: string, cancelationData: NurseCancelationForm) {
    let params = {
      reason: cancelationData.reason,
      ...(cancelationData.reasonNotes ? {reasonNotes: cancelationData.reasonNotes} : {}),
    }
    return this.http.post<ShiftModel>(
      `${this.endpoint}/shifts/${shiftId}/decline/${applicantId}`,
      params,
    );
  }

  reportApplicantNcns(shiftId: string, applicantId: string) {
    return this.http.put<ApplicantModel>(
      `${this.endpoint}/shifts/${shiftId}/ncns/${applicantId}`,
      {},
    );
  }

  undoApplicantNcns(shiftId: string, applicantId: string) {
    return this.http.delete<ApplicantModel>(
      `${this.endpoint}/shifts/${shiftId}/ncns/${applicantId}`,
      {},
    );
  }

  deleteShift(shiftId: string) {
    return this.http.delete(`${this.endpoint}/shifts/${shiftId}`);
  }

  editShift(shiftId: string, dto: EditShiftDTO) {
    return this.http.patch<ShiftModel>(
      `${this.endpoint}/shifts/${shiftId}`,
      dto,
    );
  }

  createShifts(dto: CreateShiftsDTO) {
    return this.http.post<ShiftModel[]>(`${this.endpoint}/shifts`, dto);
  }
  calculateShift(state: string, hourlyRate: string, mode: string, paymentMethod: string, feeCharged: string, processingFee?) {
    const params = new HttpParams({
      fromObject: {
        state,
        hourlyRate,
        mode,
        paymentMethod,
        feeCharged,
        ...(!isNaN(processingFee) ? {processingFee} : {}),
    }});
    return this.http.get<CalculatorModel>(`${this.endpoint}/shifts/calculator`, {params});
  }
  fetchScheduledApplicants(facility : string,
    startTimeFrom : string, startTimeTo : string, q? : string){
      const params = new HttpParams({
        fromObject: {
          facility ,
          startTimeTo,
          startTimeFrom,
          ...(q ? {q} : {})
      }});
      return this.http
      .get<NurseModel[]>(`${this.endpoint}/users/scheduled-applicants`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return {
            models: response.body,
          };
        }),
      );
  }
}
