import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  share,
  withLatestFrom,
} from 'rxjs/operators';

import { ShiftsAccessor } from 'src/app/modules/manager/services/accessors/shifts.accessor';
import { ShiftsService } from 'src/app/modules/manager/services/api/shifts.service';
import { clamp } from 'src/app/helpers/math';

import { FacilitiesAccessor } from '../../services/accessors/facilities.accessor';
import { SHIFTS_PAGE_SIZE } from 'src/app/constants/pagination';
import { FilterService } from '../../services/filter.service';
import { DnrFacade } from '../../services/facades/dnr/dnr.facade';
import { ShiftsActions } from '../actions/shifts/shifts-action-types';

@Injectable()
export class ShiftsEffects {
  constructor(
    private actions$: Actions,
    private api: ShiftsService,
    private accessor: ShiftsAccessor,
    private facilitiesAccessor: FacilitiesAccessor,
    private filterService: FilterService,
    private dnrFacade: DnrFacade
  ) {}

  fetchShifts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.fetchShifts),
      withLatestFrom(this.facilitiesAccessor.activeFacility$),
      exhaustMap(([{ filters, sort }, f]) =>
        this.api
          .fetchShifts(
            f._id,
            filters,
            { skip: 0, limit: SHIFTS_PAGE_SIZE },
            sort,
          )
          .pipe(
            map(({ models, totalCount }) =>
            ShiftsActions.fetchShiftsSuccess({
                models,
                isDone: models.length >= totalCount,
              }),
            ),
            catchError((e) => of(ShiftsActions.fetchShiftsFailed({ error: e }))),
          ),
      ),
      share(),
    ),
  );
  fetchShiftsForSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.fetchShiftsForSchedule),
      withLatestFrom(this.facilitiesAccessor.activeFacility$),
      exhaustMap(([{ filters, sort }, f]) =>
        this.api
          .fetchShifts(
            f._id,
            filters,
            { skip: 0, limit: 300 },
            sort,
          )
          .pipe(
            map(({ models, totalCount }) =>
            ShiftsActions.fetchShiftsForScheduleSuccess({
                models,
                isDone: models.length >= totalCount,
              }),
            ),
            catchError((e) => of(ShiftsActions.fetchShiftsForScheduleFailed({ error: e }))),
          ),
      ),
      share(),
    ),
  );

  fetchShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.fetchShift),
      exhaustMap(({ shiftId }) =>
        this.api.fetchShift(shiftId).pipe(
          map((model) => ShiftsActions.fetchShiftSuccess({ model })),
          catchError((e) => of(ShiftsActions.fetchShiftFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  fetchNextShiftsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.fetchNextShiftsPage),
      withLatestFrom(
        this.accessor.shiftIds$,
        this.facilitiesAccessor.activeFacility$,
      ),
      exhaustMap(([{ filters }, shifts, f]) =>
        this.api.fetchShifts(f._id, filters).pipe(
          map(({ models, totalCount }) =>
          ShiftsActions.fetchNextShiftsPageSuccess({
              models,
              isDone: models.length + shifts.length >= totalCount,
            }),
          ),
          catchError((e) => of(ShiftsActions.fetchNextShiftsPageFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  fetchPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.fetchPage),
      withLatestFrom(this.facilitiesAccessor.activeFacility$),
      exhaustMap(([{ filters, limit, page, sort, withDnrs }, f]) =>
        this.api
          .fetchShifts(
            f._id,
            filters,
            { limit, skip: clamp(page, 0, Number.MAX_SAFE_INTEGER) * limit },
            sort,
            withDnrs
          )
          .pipe(
            map(({ models, totalCount, dnrs }) => {
              const pagesCount = Math.ceil(totalCount / limit);
              if(withDnrs){
                this.dnrFacade.syncDnr(dnrs);
              }
              return ShiftsActions.fetchPageSuccess({
                models,
                limit,
                page,
                pagesCount,
                totalCount,
              });
            }),
            catchError((e) => of(ShiftsActions.fetchPageFailed({ error: e }))),
          ),
      ),
      share(),
    ),
  );

  confirmApplicant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.confirmApplicant),
      exhaustMap(({ applicantId, shiftId, increaseSlots }) =>
        this.api.confirmApplicant(shiftId, applicantId, increaseSlots).pipe(
          map((model) => {
            this.filterService.checkShift(model);
            return ShiftsActions.confirmApplicantSuccess({ model });
          }),
          catchError((e) => of(ShiftsActions.confirmApplicantFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  declineApplicant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.declineApplicant),
      exhaustMap(({ shiftId, applicantId }) =>
        this.api.declineApplicant(shiftId, applicantId).pipe(
          map((model) => {
            this.filterService.checkShift(model);
            return ShiftsActions.declineApplicantSuccess({ model });
          }),
          catchError((e) => of(ShiftsActions.declineApplicantFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  cancelApplicant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.cancelApplicant),
      exhaustMap(({ shiftId, applicantId, cancelationData }) =>
        this.api.cancelApplicant(shiftId, applicantId, cancelationData).pipe(
          map((model) => {
            this.filterService.checkShift(model);
            return ShiftsActions.cancelApplicantSuccess({ model });
          }),
          catchError((e) => of(ShiftsActions.cancelApplicantFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  reportNcns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.reportNcns),
      exhaustMap(({ shiftId, applicantId }) =>
        this.api.reportApplicantNcns(shiftId, applicantId).pipe(
          map((model) => {
            //this.filterService.checkShift(model);
            return ShiftsActions.reportNcnsSuccess({ model: model, shiftId: shiftId });
          }),
          catchError((e) => of(ShiftsActions.reportNcnsFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  undoNcns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.undoNcns),
      exhaustMap(({ shiftId, applicantId }) =>
        this.api.undoApplicantNcns(shiftId, applicantId).pipe(
          map((model) => {
            //this.filterService.checkShift(model);
            return ShiftsActions.undoNcnsSuccess({ model: model, shiftId: shiftId });
          }),
          catchError((e) => of(ShiftsActions.undoNcnsFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  deleteShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.deleteShift),
      exhaustMap(({ shiftId }) =>
        this.api.deleteShift(shiftId).pipe(
          map(() => ShiftsActions.deleteShiftSuccess({ shiftId })),
          catchError((e) => of(ShiftsActions.deleteShiftFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  createShifts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.createShifts),
      exhaustMap(({ dto }) =>
        this.api.createShifts(dto).pipe(
          map((models) => ShiftsActions.createShiftsSuccess({ models })),
          catchError((e) => of(ShiftsActions.createShiftsFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );

  editShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.editShift),
      exhaustMap(({ id, dto }) =>
        this.api.editShift(id, dto).pipe(
          map((model) => {
            this.filterService.checkShiftAfterEdit(model);
            return ShiftsActions.editShiftSuccess({ model });
          }),
          catchError((e) => of(ShiftsActions.editShiftFailed({ error: e }))),
        ),
      ),
      share(),
    ),
  );
  fetchScheduledApplicants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShiftsActions.fetchScheduledApplicants),
      withLatestFrom(this.facilitiesAccessor.activeFacility$),
      exhaustMap(([{ startTimeFrom, startTimeTo, q }, f]) =>
        this.api
          .fetchScheduledApplicants(
            f._id, startTimeFrom, startTimeTo, q
          )
          .pipe(
            map(({ models }) =>
              ShiftsActions.fetchScheduledApplicantsSuccess({
                models,
              }),
            ),
            catchError((e) => of(ShiftsActions.fetchScheduledApplicantsFailed({ error: e }))),
          ),
      ),
      share(),
    ),
  );
}
