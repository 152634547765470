export type CancelationRadioButton = {
    value: string,
    label: string,
    title: string,
    placeholder: string
}
export const cancelationRadioButtons: CancelationRadioButton[] = [
    {
      value: 'Filled by internal staff',
      label: 'Filled by internal staff',
      title: '',
      placeholder: ''
    },
    {
      value: 'Filled by other agency',
      label: 'Filled by other agency',
      title: 'Agency name',
      placeholder: 'Enter agency name'
    },
    {
      value: "Nurse can not work at our facility",
      label: "Nurse can't work at our facility",
      title: 'Please provide additional details',
      placeholder: 'Enter reason why nurse cant work at the facility'
    },
    {
      value: 'Nurse is running late',
      label: 'Nurse is running late',
      placeholder: '',
      title: ''
    },
    {
      value: 'Shift created incorrectly',
      label: 'Shift created incorrectly',
      placeholder: '',
      title: ''
    },
    {
      value: 'Other',
      label: 'Other',
      title: 'Please provide a reason for this cancelation',
      placeholder: 'Enter a reason for this cancelation'
    },
  ];